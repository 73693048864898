<template>
  <div id="app" class="row">


   
   
  <div class="col-6">
    <checkbox-svg-map height="684" v-model="selectedLocations" :map="Tunisia" />
  </div>
  <div class="col-6">
      <div class="index">
<ul v-for="(zone, key) in zones" :key="key">
  <li style="list-style: none;
	font-size: 20px;
	background-color: #f4bc44;
	border-radius: 10px;
	padding: 10px;
	margin-bottom: 10px;
	width: 400px;
	height: 50px;
  transition: .3s box-shadow;" ><a href="#"> <i
                         class="mdi mdi-map-marker"
                          style="color:red;font-size:22px;margin-bottom:10px;"
                        >
                        </i>{{zone.gouvernerat}} </a>
   </li>
  
</ul>  

</div>
  </div>
 

  
 
  </div>
</template>

<script>
import { HTTP } from '@/axios';
 import { SvgMap } from "vue-svg-map";
import Tunisia from "@svg-maps/tunisia";
import { CheckboxSvgMap } from "vue-svg-map";
export default {
   
  name: "App",
   components: {
         CheckboxSvgMap, SvgMap 
    },
  data() {
    return {
   
      Tunisia,
      selectedLocations: [],
      zones: [],
     
    };
  },
  created() {
    this.getzones();
  },
   mounted() {
    
  },
  methods: {
    getzones() {
      HTTP
        .get("zones/getzones")
        .then((response) => {
            this.zones=response.data;
            var test= [];
          this.selectedLocations=response.data
            response.data.forEach((element, index, array) => {
              test.push(element.gouvernerat.toLowerCase());

           });
           this.selectedLocations=test;
        })
        .then((err) => console.log(err));
    },
   
  },
};
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 


}


 /* path {
      fill: rgb(6, 99, 6);
      cursor: pointer;
      -webkit-transition: all 150ms;
      -moz-transition: all 150ms;
      -o-transition: all 150ms;
      transition: all 150ms;
    }
    #TN-83 {
      fill:red;
    
    }  */

    /* path:hover {
      opacity: 0.85;
    } */



    .svg-map {
  /* width: 100%; */
 pointer-events: none; 
  stroke: #666;
  stroke-width: 1;
  stroke-linecap: round;
  stroke-linejoin: round; }
  .svg-map__location {
    fill: #a1d99b;
    cursor: pointer; }
    .svg-map__location:focus, .svg-map__location:hover {
      fill: #b8e2b3;
      outline: 0; }
    .svg-map__location[aria-checked="true"] {
      fill: #f4bc44; }



.index li {
	list-style: none;
	font-size: 20px;
	background-color: #f4bc44;
	border-radius: 10px;
	padding: 10px;
	margin-bottom: 10px;
	width: 400px;
	height: 50px;
  transition: .3s box-shadow;
}
.index li:hover {
  box-shadow: 0px 0px 10px #010101;
}
.index a {
	color: rgb(243, 238, 238)
}


</style>
